import React from "react";
import { Link } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import style from "./navbar.module.scss";
import logo from "../../images/logo_ribaus.svg";

const Navbar = () => (
  <div className={`${style.Navbar} container`}>
    <div className={`${style.Navbar_left} grid-u-4-12`}>
      <AniLink
        className={style.Navbar_logo}
        paintDrip
        to="/"
        duration={1}
        hex="#3BD788"
      >
        <h1>
          <img src={logo} alt="" />
        </h1>
      </AniLink>
    </div>
    <div className={`${style.Navbar_right} grid-u-8-12`}>
      <AniLink
        className={style.Navbar_item}
        paintDrip
        duration={1}
        hex="#3BD788"
        to="/about"
      >
        About
      </AniLink>

      <AniLink
        className={`${style.Navbar_item} hightlight`}
        paintDrip
        duration={1}
        hex="#3BD788"
        to="/portfolio"
      >
        What I've done
      </AniLink>
      <AniLink
        className={style.Navbar_item}
        paintDrip
        duration={1}
        hex="#3BD788"
        to="/faq"
      >
        FAQ
      </AniLink>
      <a href="https://s3-ap-northeast-1.amazonaws.com/mipon-media/CV_Riveroll+Alfredo.pdf" style={{
        color: '#3BD788'
      }} className={style.Navbar_item} rel="nofollow noopener noreferrer" target="_blank">CV</a>
    </div>
  </div>
);

export default Navbar;
