import React from "react";
// import { Link } from "gatsby";
import style from "./footer.module.scss";

const Footer = () => (
  <div>
    <footer className={style.Footer}>
      <div className="container">
        <a href="mailto:alfrex.r@gmail.com" target="_top">Email</a>
      </div>

    </footer>
  </div>
);

export default Footer;

// <p className="grid-u-6-12">Ribaus © {new Date().getFullYear()}</p>
